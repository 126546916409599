import React, {memo, useEffect, useState} from "react";
import {Box, IconButton, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";

import {useMobileLayout, useConfirm} from "../hooks/uiHooks";
import {useUserId} from "../hooks/userHooks";
import {transferProject, ccTransfer} from "../services/ApiService";
import {areDictsEqual} from "../utils/generalUtils";
import {isLoading} from "../utils/uiUtils";

import ProjectList from "./ProjectList";
import ProgressDialog from "./dialogs/ProgressDialog";
import ProjectPreviewTransferDialog from "./dialogs/ProjectPreviewTransferDialog";
import RestrictedContent from "./RestrictedContent";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Spinner from "./Spinner";

import {DefaultStrings, DeviceStrings} from "../strings";

const useStyles = makeStyles((theme) => {
  return {
    root: (mobile) => ({
      height: "100%",
      width: "100%",
      padding: theme.spacing(mobile ? 1 : 2),
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    }),
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    listContainer: {
      flexGrow: 1,
      height: 1,
    },
    stretchWidth: (mobile) => ({
      flexGrow: 1,
      width: mobile ? "100%" : 1,
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }),
    stretchHeight: {
      flexGrow: 1,
      height: 1,
      paddingTop: theme.spacing(2),
      "&:first-child": {
        paddingTop: 0,
      },
    },
    iconButton: {
      padding: 0,
    },
    dialog: (mobile) => ({
      width: mobile ? "100%" : 320,
      height: mobile ? "100%" : "80%",
    }),
    actions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
    },
    button: {
      width: "60%",
      margin: theme.spacing(1),
      "&:last-child": {
        marginTop: 0,
      },
    },
  };
});

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.active === props2?.active &&
  props1?.dismiss === props2?.dismiss &&
  areDictsEqual(props1.permissions, props2.permissions);

const DeviceTransfer = ({
  deviceId,
  projectId,
  permissions,
  active,
  dismiss,
  useCc = false, // CC = old cloud control app
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const userId = useUserId();
  const confirm = useConfirm();
  const {enqueueSnackbar} = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [activeProjectId, setActiveProjectId] = useState();
  const [showProgressDialog, setShowProgressDialog] = useState(false);

  // check dismiss
  useEffect(() => {
    if (dismiss) {
      setActiveProjectId(null);
      setShowProgressDialog(false);
    }
  }, [dismiss]);

  if (isLoading(deviceId) || isLoading(active)) return <Spinner />;

  if (!deviceId) return <></>;

  const onClickProject = (projectId) => {
    // show PreviewDialog
    setActiveProjectId(projectId);
  };

  const onTransfer = async () => {
    // dismiss PreviewDialog
    setActiveProjectId(null);

    // show ProgressDialog
    // DevicePanel will detect project change and dismiss
    setShowProgressDialog(true);

    const newProjectId = activeProjectId;
    const tranFunc = useCc ? transferProject : ccTransfer;
    const result = await tranFunc({
      deviceId,
      projectId,
      newProjectId,
    });

    if (!result.success) {
      enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
      console.warn(result.errors[0]);
    }
  };

  const endAdornment =
    searchString.length === 0 ? (
      <SearchIcon />
    ) : (
      <IconButton
        className={classes.iconButton}
        onClick={() => setSearchString("")}
      >
        <CloseIcon />
      </IconButton>
    );

  const selectProject = (
    <div className={classes.main}>
      <Box m={1}>{DeviceStrings.TRANSFER_DESC}</Box>
      <TextField
        label={DeviceStrings.TRANSFER_SEARCH}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        size="small"
        InputProps={{
          endAdornment,
        }}
      ></TextField>
    </div>
  );

  const configProjectList = {
    filter: searchString,
    userId,
    onClick: onClickProject,
    exclude: [projectId],
    small: true,
  };

  const configPreviewDialog = {
    projectId: activeProjectId,
    onClose: () => {
      setActiveProjectId(null);
    },
    onConfirm: () => {
      const message = DeviceStrings.TRANSFER_CONFIRM;
      confirm({message})
        .then(() => {
          onTransfer();
        })
        .catch((error) => {});
    },
  };

  // usually we want to avoid repetitive mount and unmount, but ProjectList is expensive we don't want it to load until it's active
  // ie. postpone the loading until we actually want to transfer
  return (
    <RestrictedContent permitted={permissions?.canWriteDevices}>
      <div className={classes.root}>
        {selectProject}
        <div className={classes.listContainer}>
          {active && <ProjectList config={configProjectList} />}
        </div>
        <ProgressDialog
          open={showProgressDialog}
          config={{
            title: DeviceStrings.TRANSFER_TITLE,
            desc: DeviceStrings.TRANSFER_PROGRESS,
          }}
        />
        <ProjectPreviewTransferDialog config={configPreviewDialog} />
      </div>
    </RestrictedContent>
  );
};

export default memo(DeviceTransfer, isEqual);
