import React from "react";
import {Provider, useSelector} from "react-redux";
import {ReactReduxFirebaseProvider, isLoaded} from "react-redux-firebase";
import {createFirestoreInstance} from "redux-firestore";
import {Router, Switch, Redirect, Route} from "react-router-dom";
import {createBrowserHistory} from "history";
import {CssBaseline} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {SnackbarProvider} from "notistack";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import store from "./redux/store";
import firebaseConfig from "./configs/firebaseConfig";
import rrfConfig from "./configs/rrfConfig";

import Spinner from "./ui/Spinner";
import {ROUTES} from "./route";
import AuthRoute from "./route/AuthRoute";
import {
  userExist,
  userNotExist,
  userExistAndInProject,
  userIsAdmin,
  alwaysAllow,
  userAndDevicesExistInProject
} from "./services/AuthService";
import "./css/App.css";
import themeMyPlayer from "./configs/theme";
import ConfirmDialogProvider from "./context/ConfirmDialogProvider";

import NewsPage from "./pages/news";

import {
  ProjectsPage,
  ProjectOverviewPage,
  ProjectMediaPage,
  ProjectDevicesPage,
  ProjectAnalyticsPage,
  ProjectTeamPage,
} from "./pages/projects";
import {ProfilePage} from "./pages/profile";
import HomePage from "./pages/home";
import MediaPage from "./pages/media";
import DownloadPage from "./pages/Download";
import UpdateDevicePage from "./pages/updateDevice";
import RemotePage from "./pages/RemotePage";
import DeviceRecoveryPage from "./pages/DeviceRecoveryPage";

import {DefaultStrings, ProjectStrings} from "./strings/";
// firebase.firestore.setLogLevel('debug');

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const customHistory = createBrowserHistory();

firebase.initializeApp(firebaseConfig);

export const Firestore = firebase.firestore();
export const Firebase = firebase.app();
export const Storage = firebase.storage().ref();
export const FieldPath = firebase.firestore.FieldPath;
export const FieldValue = firebase.firestore.FieldValue;

export const DefaultPage = <Redirect to={ROUTES.LOGIN} />;

/**
 * below is for running Firestore emulator
 */
// if (window.location.hostname === "localhost") {
//   console.debug("Using Firestore emulator...")
//   Firestore.settings({
//     host: "localhost:8080",
//     ssl: false
//   });
// }

// show spinner when auth is not loaded
const AuthIsLoaded = ({children}) => {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  if (!isLoaded(auth) || !profile.isLoaded) return <Spinner />;
  return children;
};
const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <MuiThemeProvider theme={themeMyPlayer}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={3000}
        >
          <ConfirmDialogProvider>
            <DndProvider backend={HTML5Backend}>
              <AuthIsLoaded>
                <CssBaseline />
                <Router history={customHistory}>
                  <Switch>
                    {/* general user routes */}
                    <AuthRoute
                      exact
                      path={[
                        ROUTES.PROJECTS,
                        ROUTES.PROJECT_JOIN,
                        ROUTES.PROJECT_NEW,
                      ]}
                      component={ProjectsPage}
                      authCheckFunc={userExist}
                      authFailPath={ROUTES.LOGIN}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.PROFILE}
                      component={ProfilePage}
                      authCheckFunc={userExist}
                      authFailPath={ROUTES.LOGIN}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.NEWS}
                      component={NewsPage}
                      authCheckFunc={userExist}
                      authFailPath={ROUTES.LOGIN}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.LOGIN}
                      component={HomePage}
                      authCheckFunc={userNotExist}
                      authFailPath={ROUTES.PROJECTS}
                      redirectToOriginal
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.SIGNUP}
                      component={HomePage}
                      authCheckFunc={userNotExist}
                      authFailPath={ROUTES.PROJECTS}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.MEDIA}
                      component={MediaPage}
                      authCheckFunc={alwaysAllow}
                      skipCookieBanner={true}
                    />

                    {/* project routes */}
                    <AuthRoute
                      exact
                      path={ROUTES.PROJECT_OVERVIEW}
                      component={ProjectOverviewPage}
                      authCheckFunc={userExistAndInProject}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
                    />
                    <AuthRoute
                      exact
                      path={[
                        ROUTES.PROJECT_MEDIA,
                        ROUTES.PROJECT_MEDIA_MESSAGES,
                        ROUTES.PROJECT_MEDIA_EDIT_TAB,
                        ROUTES.PROJECT_MEDIA_PREVIEW,
                      ]}
                      component={ProjectMediaPage}
                      authCheckFunc={userExistAndInProject}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
                    />
                    <AuthRoute
                      exact
                      path={[
                        ROUTES.PROJECT_DEVICES,
                        ROUTES.PROJECT_DEVICE_SINGLE,
                        ROUTES.PROJECT_DEVICE_TAB,
                      ]}
                      component={ProjectDevicesPage}
                      authCheckFunc={userAndDevicesExistInProject}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.PROJECT_ANALYTICS}
                      component={ProjectAnalyticsPage}
                      authCheckFunc={userExistAndInProject}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
                    />
                    <AuthRoute
                      exact
                      path={[
                        ROUTES.PROJECT_TEAM,
                        ROUTES.PROJECT_TEAM_MANAGE,
                        ROUTES.PROJECT_TEAM_SHARE,
                      ]}
                      component={ProjectTeamPage}
                      authCheckFunc={userExistAndInProject}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={ProjectStrings.ERROR_NO_ACCESS}
                    />

                    {/* admin routes */}
                    <AuthRoute
                      exact
                      path={[ROUTES.REMOTE_MAIN, ROUTES.REMOTE_DEVICE]}
                      component={RemotePage}
                      authCheckFunc={userIsAdmin}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={DefaultStrings.ERROR_NO_ACCESS}
                    />
                    <AuthRoute
                      exact
                      path={ROUTES.DEVICE_RECOVERY}
                      component={DeviceRecoveryPage}
                      authCheckFunc={userIsAdmin}
                      authFailPath={ROUTES.PROJECTS}
                      authFailMessage={DefaultStrings.ERROR_NO_ACCESS}
                    />

                    <Route
                      exact
                      path={ROUTES.DOWNLOAD}
                      component={DownloadPage}
                    />

                    <Route
                      exact
                      path={ROUTES.UPDATE_DEVICE}
                      component={UpdateDevicePage}
                    />

                    {/* catch-all (with no path) */}
                    {DefaultPage}
                  </Switch>
                </Router>
              </AuthIsLoaded>
            </DndProvider>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default App;
