import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import {
  useRelativeTimeString,
  displayLoadingText,
} from "../../utils/localeUtils";
import { useConfirm } from "../../hooks/uiHooks";
import { requestDeploy, ccDeploy } from "../../services/ApiService";
import { useDeviceLastDeployedTimestamp } from "../../services/ProjectService";

import ButtonCard from "./ButtonCard";
import DeploymentDialogV2 from "../dialogs/DeploymentDialogV2";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CallMadeIcon from "@material-ui/icons/CallMade";

import { DefaultStrings, DeviceStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  };
});

const DEPLOY_BUFFER = 5 * 1000; // 5 sec

const DeviceDeploymentCard = ({
  deviceId,
  projectId,
  showDesc,
  canRead,
  canEdit,
  useCc = false, // CC = old cloud control app
}) => {
  const confirm = useConfirm();
  const [inProgress, setInProgress] = useState([]);
  const [requestTimestamp, setRequestTimestamp] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(false);
  const classes = useStyles();

  // v2
  const lastDeployedTimestamp = useDeviceLastDeployedTimestamp(
    projectId,
    deviceId
  );

  const lastDeployEnd = useRelativeTimeString(lastDeployedTimestamp);
  const displayLastDeployEnd = displayLoadingText(
    lastDeployEnd,
    DeviceStrings.NEVER
  );
  const hasDeployedBefore = !!lastDeployEnd;

  const deviceLoading = inProgress.includes(deviceId);

  // track mounted state
  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      requestTimestamp &&
      lastDeployedTimestamp &&
      lastDeployedTimestamp - requestTimestamp >= -DEPLOY_BUFFER
    ) {
      // stop progress
      if (mounted.current && deviceLoading)
        setInProgress(inProgress.filter((i) => i !== deviceId));
    }
  }, [
    requestTimestamp,
    lastDeployedTimestamp,
    deviceId,
    inProgress,
    deviceLoading,
  ]);

  const deploy = () => {
    setInProgress([...inProgress, deviceId]);
    setRequestTimestamp(Date.now());

    const call = useCc ? requestDeploy : ccDeploy;
    call({ deviceId, projectId }).then((result) => {
      if (result.success) {
        enqueueSnackbar(DeviceStrings.REQUEST_SENT, { variant: "success" });
      } else {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(result.errors[0]);
      }
      // if (mounted.current) setInProgress(false);
    });
  };

  const onConfirm = () => {
    const message = DeviceStrings.DEPLOY_CONFIRM_MSG;
    confirm({ message })
      .then(() => {
        deploy();
      })
      .catch((error) => {});
  };

  const title = DeviceStrings.DEPLOY_TITLE;

  const configCard = {
    icon: <CloudDownloadOutlinedIcon />,
    title: useCc ? `${title} (CC)` : title,
    buttonLabel: DeviceStrings.DEPLOY_BUTTON,
    desc: showDesc ? DeviceStrings.DEPLOY_DESC : null,
    fullHeight: true,
    onClick: () => {
      onConfirm();
    },
    progress: deviceLoading,
    disableButton: deviceLoading || !canEdit,
  };

  const progressIcon = (hasDeployedBefore || deviceLoading) && (
    <IconButton className={classes.icon} onClick={() => setShowDialog(true)}>
      <CallMadeIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <ButtonCard config={configCard} canRead={canRead}>
        <b>{DeviceStrings.LAST_DEPLOYED}</b>
        {displayLastDeployEnd && " " + displayLastDeployEnd}
        {progressIcon}
      </ButtonCard>
      <DeploymentDialogV2
        open={showDialog}
        deviceId={deviceId}
        projectId={projectId}
        onClose={() => {
          if (mounted.current) setShowDialog(false);
        }}
      />
    </>
  );
};

export default DeviceDeploymentCard;
