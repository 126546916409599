import { useEffect, useState } from "react";
import RelativeTimeFormat from "relative-time-format";
import en from "relative-time-format/locale/en.json";

import countries from "./countries.json";

import { DefaultStrings } from "../strings";

RelativeTimeFormat.addLocale(en);

const rtf = new RelativeTimeFormat("en", {
  style: "long", // "long" is the default. Other options: "short", "narrow".
});

const DEFAULTS = {
  // 24 hours
  DEVICE_ONLINE_THRESHOLD: 24 * 60 * 60 * 1000,

  FILE_SIZE_PRECISION: 0,
  LOCALE: "en",
};

const localeToCountry = {};

countries.forEach((country) => {
  localeToCountry[country.alpha2] = country.name;
});

export const getCountryFromLocale = (locale) =>
  locale in localeToCountry && localeToCountry[locale];

export const getTimeString = (timestamp) =>
  new Date(timestamp).toLocaleString();

export const getMomentTimeString = (moment) =>
  new Date(moment.substring(0, 19).concat(".000Z")).toLocaleString();

export const getLocalTimeString = (date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .replace("T", " ") // remove T
    .slice(0, -5); // remove ms

export const getDateString = (timestamp) => {
  const date = new Date(timestamp);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);
};

export const dayInMs = 24 * 60 * 60 * 1000;

export const isOnline = (updatedDate) => {
  const onlineFrom = new Date(Date.now() - DEFAULTS.DEVICE_ONLINE_THRESHOLD);
  return updatedDate > onlineFrom;
};

export const getLastOnlineDate = (clearHours = false, clearMinutes = false) => {
  const lastDate = new Date(Date.now() - DEFAULTS.DEVICE_ONLINE_THRESHOLD);
  if (clearHours) lastDate.setHours(0);
  if (clearMinutes) lastDate.setMinutes(0);
  lastDate.setSeconds(0);
  lastDate.setMilliseconds(0);
  return lastDate;
};

// size in KB
export const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size.toFixed(DEFAULTS.FILE_SIZE_PRECISION)} Bytes`;
  } else if (size < 1048576) {
    return `${(size / 1024).toFixed(DEFAULTS.FILE_SIZE_PRECISION)} KB`;
  } else if (size < 1073741824) {
    return `${(size / 1048576).toFixed(DEFAULTS.FILE_SIZE_PRECISION)} MB`;
  } else {
    return `${(size / 1073741824).toFixed(DEFAULTS.FILE_SIZE_PRECISION)} GB`;
  }
};

const UNITS = [
  { threshold: 60, unit: "second", divider: 1 },
  { threshold: 3600, unit: "minute", divider: 60 },
  { threshold: 24 * 3600, unit: "hour", divider: 3600 },
  { threshold: 7 * 24 * 3600, unit: "day", divider: 24 * 3600 },
  { threshold: 30 * 24 * 3600, unit: "week", divider: 7 * 24 * 3600 },
  { threshold: 365 * 24 * 3600, unit: "month", divider: 30 * 24 * 3600 },
  { unit: "year", divider: 365 * 24 * 3600 },
];

export const useRelativeTimeString = (timestamp, intervalMs = 1000) => {
  const [res, setRes] = useState(getRelativeTimeString(timestamp));
  useEffect(() => {
    const id = setInterval(() => {
      setRes(getRelativeTimeString(timestamp));
    }, intervalMs);
    return () => clearInterval(id);
  }, [timestamp, intervalMs]);
  return res;
}

// timestamp should be number
export const getRelativeTimeString = (timestamp) => {
  if (!timestamp) return "";
  // const timeFormat = new Intl.RelativeTimeFormat(DEFAULTS.LOCALE);
  const timeDiff = (Date.now() - timestamp) / 1000;
  let formatUnit = null;
  let formatTime = null;
  UNITS.some(({ threshold, unit, divider }) => {
    if (!threshold || timeDiff < parseInt(threshold)) {
      formatUnit = unit;
      formatTime = (timeDiff / divider).toFixed(0);
      return true;
    }
    return false;
  });
  // return timeFormat.format(-formatTime, formatUnit);
  return rtf.format(-formatTime, formatUnit);
};

export const displayLoadingText = (text, emptyText = "") =>
  typeof text === "undefined"
    ? DefaultStrings.FETCHING_DATA
    : text
    ? text
    : emptyText;

const DATE_OPTIONS = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const formatLocaleDate = (date) =>
  date && new Date(date).toLocaleDateString();

export const formatLocaleDateLong = (date) => {
  const locales = undefined; // default locale
  return date && new Date(date).toLocaleDateString(locales, DATE_OPTIONS);
};

export const formatLocaleTime = (date) =>
  date &&
  new Date(date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

export const getDateOnlyTime = (date) => {
  const dateOnly = date ? new Date(date) : new Date();
  dateOnly.setHours(0, 0, 0, 0); // clear time
  return dateOnly.getTime();
};
