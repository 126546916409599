/**
 * TrackingService is a portal providing tracking service from various engines.
 * 
 * All engines should provide the following methods:
 * - constructor(id, options, callback)
 * - track(key, data)
 * - set(kev, value)
 */
import {createGoogleAnalytics4} from "./tracking/GoogleAnalytics4";

export const ENGINES = {
  GOOGLE_ANALYTICS_4: "GoogleAnalytics4",
};

export const createTracking = (engineType, id, options, callback) => {
  switch (engineType) {
    case ENGINES.GOOGLE_ANALYTICS_4:
    default:
      return createGoogleAnalytics4(id, options, callback);
  }
};
