import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { makeStyles } from "@material-ui/core/styles";

import { useDefaultCommand } from "../services/TaskService";
import { useConfirm } from "../hooks/uiHooks";

import MyCard from "../ui/cards/MyCard";

import { DefaultStrings, ProjectStrings } from "../strings";

const useStyles = makeStyles((theme) => ({
  taskCard: ({ isDragging }) => ({
    padding: 4,
    marginBottom: 8,
    opacity: isDragging ? 0.2 : 1,
  }),
  taskCardContent: (mobile) => ({
    marginRight: theme.spacing(mobile ? 0 : 6),
    display: "flex",
    flexDirection: "column",
  }),
  taskCardChips: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    border: "solid 0.5px #C0C0C0",
    borderRadius: theme.spacing(0.5),
  },
  taskCardDropdown: {
    flexGrow: 1,
    width: 1,
    margin: 5,
  },
  taskCommand: {
    overflowWrap: "anywhere",
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  accordionMain: {
    backgroundColor: theme.palette.background.default,
  },
}));

const TaskCard = ({
  projectId,
  task, // task info object
  canRead,
  progress, // in progress (loading)
  index, // index on list for drag and drop
  onDragging,
  onEndDrag,
  onDelete,
}) => {
  //   const defaultTasks = useDefaultTasks();
  //   const commands = useDefaultCommands();
  const command = useDefaultCommand(task?.commandType);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const startIndex = index;

  const name = command?.title; // defaultTasks?.[task?.taskId]?.name;

  const [, drop] = useDrop({
    accept: "card",
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverBoundingRect.height * 0.8
      ) {
        return;
      }
      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverBoundingRect.height * 0.2
      ) {
        return;
      }
      // Time to actually perform the action
      onDragging(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => ({ startIndex, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (onEndDrag) onEndDrag(item.startIndex, item.index);
    },
  });

  const classes = useStyles({ isDragging });

  const onClickRemove = () => {
    const message = `${ProjectStrings.TASKS_MSG_CONFIRM_REMOVE_TASK} ${name}?`;
    confirm({ message }).then(() => {
      onDelete({
        projectId,
        index,
        commandType: task?.commandType,
      }).then((result) => {
        if (result.success) {
          enqueueSnackbar(ProjectStrings.TASKS_MSG_TASK_REMOVED, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          console.error(result.errors[0]);
        }
      });
    });
  };

  const config = {
    icon: <span className="material-symbols-outlined">feed</span>,
    title: name,
    progress,
    overflow: [
      //   {
      //     label: task.expanded
      //       ? ProjectStrings.TASKS_MENU_COLLAPSE_TASK
      //       : ProjectStrings.TASKS_MENU_EXPAND_TASK,
      //     onClick: onExpandInternal,
      //   },
      {
        label: ProjectStrings.TASKS_MENU_DELETE_TASK,
        onClick: onClickRemove,
      },
    ],
  };

  drag(drop(ref));

  //   return (
  //     <Accordion ref={ref} className={classes.accordionMain}>
  //       <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
  //         {name}
  //       </AccordionSummary>
  //       <AccordionDetails
  //         style={{ display: "flex", alignItems: "center", width: "100%" }}
  //       >
  //         <div style={{ display: "flex", gap: 8, flexGrow: 1 }}>
  //           {Object.entries(task.input).map(([k, v]) => (
  //             <Chip
  //               key={`chip-${k}`}
  //               label={`${k} = ${v}`}
  //               style={{ marginLeft: 8 }}
  //             />
  //           ))}
  //         </div>
  //         <Button
  //           variant="contained"
  //           //   onClick={onClickInternal}
  //           // disabled={disabled}
  //         >
  //           {ProjectStrings.TASKS_MENU_DELETE_TASK}
  //         </Button>
  //       </AccordionDetails>
  //     </Accordion>
  //   );

  return (
    <div ref={ref} className={classes.taskCard}>
      <MyCard config={config} canRead={canRead}>
        {task?.input &&
          Object.entries(task.input).map(([k, v]) => (
            <Chip
              key={`chip-${k}`}
              label={`${k} = ${v}`}
              style={{ marginLeft: 8 }}
            />
          ))}
      </MyCard>
    </div>
  );
};

// hash all the tasks as useEffect dependency
const tasksHash = (t) => JSON.stringify(t);

const TasksList = ({ projectId, tasks, canRead, onMove, onDelete }) => {
  const { enqueueSnackbar } = useSnackbar();
  // tasks is from database
  // tasksCache is temporary for display and dragging
  const [tasksCache, setTasksCache] = useState();
  const [updating, setUpdating] = useState();

  const hash = tasksHash(tasks);

  const updateCache = useCallback(() => {
    setTasksCache((prevCache) =>
      tasks.map((t, ti) => ({
        ...t,
        id: ti,
        expanded: prevCache?.[ti] ? prevCache[ti].expanded : false,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (!tasks) return;
    updateCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, updateCache]);

  const onDragging = useCallback((dragIndex, hoverIndex) => {
    setTasksCache((prevCache) =>
      update(prevCache, {
        $splice: [
          [dragIndex, 1], // remove dragging
          [hoverIndex, 0, prevCache[dragIndex]], // insert dragging into hover
        ],
      })
    );
  }, []);

  const onEndDrag = (startIndex, index) => {
    // same position
    if (startIndex === index) return;
    setUpdating(startIndex);
    onMove({
      projectId,
      index: startIndex,
      newIndex: index,
    })
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(ProjectStrings.TASKS_MSG_TASK_MOVED, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          console.warn("moveProjectTask", result.errors[0]);
        }
      })
      .finally(() => {
        setUpdating(null);
        updateCache();
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      {tasksCache?.map((t) => {
        return (
          <TaskCard
            key={`task-${t.id}`}
            projectId={projectId}
            task={t}
            index={t.id}
            canRead={canRead}
            onDragging={onDragging}
            onEndDrag={onEndDrag}
            onDelete={onDelete}
            progress={updating === t?.id}
          />
        );
      })}
    </div>
  );
};

export default TasksList;
