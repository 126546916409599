import React, { useState, useEffect } from "react";
import { Chip } from "@material-ui/core";

import MyDialog from "ui/MyDialog";
import MyCard from "ui/cards/MyCard";
import ButtonCard from "ui/cards/ButtonCard";
import { useStyles } from "./styles";
import DialogContent from "./DialogContent"

const MAX_HARDWARE_TAGS_COUNT = 30;
const DELIMITER = "::";
const VARIANT = { MY_CARD: 'my-card', BUTTON_CARD: 'button-card' };

/* Sample of MyCard config
  const configCard = {
    overflowId: OVERFLOW_ID,
    info: ProjectStrings.OVERVIEW_HARDWARE_CARD_INFO,
    onClick: () => setShowDialog(true),
    progress: progress,
  };
*/

/* Sample of ButtonCard config
  const configCard = {
    buttonLabel: DeviceStrings.GENERAL_EDIT_TAG_BUTTON,
    fullHeight: true,
    onClick: () => setShowDialog(true),
    progress: progress,
    disableButton: !canEdit,
  };
*/

/* Sample of MyDialog config
  const configDialog = {
    inputTitle: DeviceStrings.GENERAL_EDIT_TAG_INPUT_TITLE,
    maxTagsCount: 30,
    errorMessage: DeviceStrings.GENERAL_EDIT_TAG_MAX_LIMIT,
    onModalClose: () => setShowDialog(false),
    onModalOk: (itemsToUpdate) => {
      setShowDialog(false);
      setProgress(true);
      updateProjectHardware({
        projectId,
        hardware: itemsToUpdate,
      }).finally(() => {
        setProgress(false);
      });
    },
  };
*/

const Tags = ({ config, showDialog, canRead, canEdit, variant }) => {
  const { title, items, suggestedItems, icon, card, dialog } = config;
  const { overflowId } = card;
  const {
    inputTitle, maxTagsCount = MAX_HARDWARE_TAGS_COUNT,
    errorMessage, onModalClose, onModalOk,
  } = dialog;

  const classes = useStyles();

  const [itemsToUpdate, setItemsToUpdate] = useState(items);
  const [suggestedToUpdate, setSuggestedToUpdate] = useState({...suggestedItems});
  const itemsJoinedStr = [...items].join(';');
  const suggestedItemsJoinedStr = Object.entries({...suggestedItems}).join(';');
  const configCard = { title, icon, ...card };

  useEffect(() => {
    if (itemsJoinedStr) {
      setItemsToUpdate(items);
    }
    if (suggestedItemsJoinedStr) {
      setSuggestedToUpdate(suggestedItems);
    }
    // eslint-disable-next-line
  }, [itemsJoinedStr, suggestedItemsJoinedStr]);

  const configDialog = {
    title,
    icon,
    onClose: () => {
      // reset edit to original
      setItemsToUpdate([...items]);
      setSuggestedToUpdate({ ...suggestedItems });
      onModalClose();
    },
    onOk: () => {
      onModalOk(itemsToUpdate, suggestedToUpdate);
    },
    // disable OK button
    disableOk: 
      !itemsToUpdate ||
      itemsToUpdate.join(DELIMITER) === items.join(DELIMITER),
  };
  
  const onChangeHandle = (keyToUpdate, action) => {
    // Only updating suggested list
    if (suggestedItems) {
      let state = {...suggestedToUpdate};
      let occurrence = state[keyToUpdate];

      if (action === 'remove' && occurrence === 1) {
        // Remove tag if occurrenceis going to be "0"
        delete state[keyToUpdate];

      } else if (action === 'add' && !state[keyToUpdate]) {
        // Add if tag is newly created (not in the sugested list)
        state = { ...state, [keyToUpdate]: 1 };

      } else {
        if (state[keyToUpdate]) {
          // Increase/decrease occurrence value  
          occurrence = action === 'add' ? ++state[keyToUpdate] : --state[keyToUpdate];
          state[keyToUpdate] = occurrence;
        }
      }
      // Update suggested state
      setSuggestedToUpdate(state);      
    }
    
    // Updating list items
    if (action === 'remove') {
      // Update items on remove
      setItemsToUpdate(itemsToUpdate.filter(tag => tag !== keyToUpdate));
    }

    if (action === 'add') {
      // Update items on add
      setItemsToUpdate([...itemsToUpdate, keyToUpdate].sort((a, b) => a < b ? -1 : 1));
    }
  };

  const cardContent = () => {
    return (
      <div className={classes.content}>
        <div id={overflowId}>
          {items && items
            .map(item => (
            <Chip key={item} label={item} className={classes.chip}></Chip>
          ))}
        </div>
      </div>
    )
  };

  return (
    <>
      {/* Show MyCard content wrapper component */}
      {variant === VARIANT.MY_CARD && <MyCard config={configCard} canRead={canRead} canEdit={canEdit}>
        {cardContent()}
      </MyCard>}
      {/* Show ButtonCard content wrapper component */}
      {variant === VARIANT.BUTTON_CARD && <ButtonCard config={configCard} canRead={canRead} canEdit={canEdit}>
        {cardContent()}
      </ButtonCard>}
      <MyDialog open={showDialog} config={configDialog}>
        <DialogContent
          items={items}
          suggestedItems={suggestedItems}
          maxTagsCount={maxTagsCount}
          inputTitle={inputTitle}
          errorMessage={errorMessage}
          onChange={onChangeHandle} />
      </MyDialog>
    </>
  );
};

export default Tags;
