import React, { memo, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { ccOnline, ccCompliance, ccDeploy } from "services/ApiService";
import {
  useProjectDevices,
  useLiveDevicesCount,
  useTotalDevicesCount,
} from "services/DeviceService";
import { debounce } from "services/UiService";
import { isLoading } from "utils/uiUtils";
import {
  DEVICE_SORT_OPTION_FIELD,
  DEVICE_SORT_OPTION,
} from "utils/deviceUtils";
import { useMobileLayout, useConfirm } from "hooks/uiHooks";

import MobileContent from "ui/MobileContent";
import InstructionContent from "ui/InstructionContent";
import Spinner from "ui/Spinner";
import MyDialog from "ui/MyDialog";
import DeviceTransfer from "ui/DeviceTransfer";
import { Dropdown } from "components";

import { DeviceStrings, DefaultStrings } from "strings";
import { List } from "../index";
import { isDeviceListEqual } from "../../utils";
import { useStyles } from "./styles";
import { buildFilterItems, filterByKey } from "./utils";

const DISPLAY_ITEMS = {
  DEFAULT: 48,
  // MAX: 240,
  MAX: 100100100,
  LOAD_MORE: 24,
  MOBILE_DIVIDER: 6,
};

// TODO: this component has grown too big and complicated and needs a refactor
const DeviceList = memo(
  ({
    projectId,
    filter,
    live,
    sortOption,
    sortDesc,
    permissions,
    onClickDevice,
    multiple,
  }) => {
    const mobile = useMobileLayout();
    const classes = useStyles(mobile);

    // params for data
    const itemDefault =
      DISPLAY_ITEMS.DEFAULT / (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1);
    const [limit, setLimit] = useState(itemDefault);
    const sort =
      sortOption && sortOption in DEVICE_SORT_OPTION_FIELD
        ? DEVICE_SORT_OPTION_FIELD[sortOption]
        : DEVICE_SORT_OPTION_FIELD[DEVICE_SORT_OPTION[0]];
    const params = {
      limit,
      startAt: 0,
      orderBy: sort,
      orderDesc: sortDesc,
      filter,
      live,
    };

    // service data
    const devices = useProjectDevices({ projectId, params });
    const allDevices = useTotalDevicesCount(projectId);
    const liveDevices = useLiveDevicesCount(projectId);
    const total = live ? liveDevices : allDevices - liveDevices;

    const [selectedDevices, setSelectedDevices] = useState({});
    const actionsDisabled =
      Object.entries(selectedDevices).filter(([k, v]) => v).length === 0;
    const deviceIds = devices.map((d) => d.deviceId);
    const selectedDeviceIds = Object.entries(selectedDevices)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k);
    const selectedCount = selectedDeviceIds.length;

    const [selectMultiple, setSelectMultiple] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const deviceTransferred = selectedDeviceIds.some(
      (id) => !deviceIds.includes(id)
    );

    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();

    // filter
    const [selectedCountries, setSelectedCountries] = useState([
      DeviceStrings.FILTER_ALL,
    ]);
    const [selectedRetailers, setSelectedRetailers] = useState([
      DeviceStrings.FILTER_ALL,
    ]);
    const [selectedTags, setSelectedTags] = useState([
      DeviceStrings.FILTER_ALL,
    ]);

    let devicesFiltered = devices;

    /** Countries filter */
    const countryKey = "country";
    const selectedAllCountries =
      selectedCountries.length === 1 &&
      selectedCountries[0] === DeviceStrings.FILTER_ALL;

    var countries = buildFilterItems(devices, countryKey);
    var countriesItems = [...countries.values()];

    if (!selectedAllCountries) {
      devicesFiltered = filterByKey(
        devicesFiltered,
        selectedCountries,
        countryKey
      );
    }

    const countriesJointedStr = JSON.stringify(countriesItems);

    useEffect(() => {
      if (countriesJointedStr) {
        // When countries list changed
        // compare the devices countries list and remove all not existed countries from the selected list
        // then update selected state
        const arr = [];
        selectedCountries.forEach((country) => {
          if (countries.has(country)) {
            arr.push(country);
          }
        });

        setSelectedCountries(
          arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr
        );
      }
      // eslint-disable-next-line
    }, [countriesJointedStr]);
    /** -------- */

    /** Reatails filter */
    const retailerKey = "retailerName";
    const selectedAllRetailers =
      selectedRetailers.length === 1 &&
      selectedRetailers[0] === DeviceStrings.FILTER_ALL;

    var retailers = buildFilterItems(devices, retailerKey);
    var retailersItems = [...retailers.values()];

    if (!selectedAllRetailers) {
      devicesFiltered = filterByKey(
        devicesFiltered,
        selectedRetailers,
        retailerKey
      );
    }

    const retailersJointedStr = JSON.stringify(retailersItems);

    useEffect(() => {
      if (retailersJointedStr) {
        // When retailers list changed
        // compare the devices retailers list and remove all not existed retailers from the selected list
        // then update selected state
        const arr = [];
        selectedRetailers.forEach((retailer) => {
          if (retailers.has(retailer)) {
            arr.push(retailer);
          }
        });
        setSelectedRetailers(
          arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr
        );
      }
      // eslint-disable-next-line
    }, [retailersJointedStr]);
    /** -------- */

    /** Tags filter */
    const tagsKey = "tags";
    const selectedAllTags =
      selectedTags.length === 1 && selectedTags[0] === DeviceStrings.FILTER_ALL;

    // Get tags for dropdown list
    var tags = buildFilterItems(devices, tagsKey);
    var tagsItems = [...tags.values()];

    // Filter devices by tags
    if (!selectedAllTags) {
      devicesFiltered = filterByKey(devicesFiltered, selectedTags, tagsKey);
    }

    const tagsJointedStr = JSON.stringify(tagsItems);

    useEffect(() => {
      if (tagsJointedStr) {
        // When tags list changed (removed tag with count 0 from a device)
        // compare the devices tags list and remove all not existed tags from the selected list
        // then update selected state
        const arr = [];
        selectedTags.forEach((tag) => {
          if (tags.has(tag)) {
            arr.push(tag);
          }
        });

        setSelectedTags(arr.length === 0 ? [DeviceStrings.FILTER_ALL] : arr);
      }
      // eslint-disable-next-line
    }, [tagsJointedStr]);
    /** -------- */

    // update all dropdown items
    countries = buildFilterItems(devicesFiltered, countryKey);
    countriesItems = [...countries.values()];
    retailers = buildFilterItems(devicesFiltered, retailerKey);
    retailersItems = [...retailers.values()];
    tags = buildFilterItems(devicesFiltered, tagsKey);
    tagsItems = [...tags.values()];

    // multiple is just a toggle trigger here
    useEffect(() => {
      setSelectMultiple(!selectMultiple);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple]);

    // monitor device transferred
    useEffect(() => {
      if (deviceTransferred) {
        setSelectedDevices({});
        setSelectMultiple(false);
        setShowDialog(false);
        enqueueSnackbar(DeviceStrings.TRANSFER_COMPLETED, {
          variant: "success",
        });
      }
    }, [deviceTransferred, enqueueSnackbar]);

    if (isLoading(devices) || isLoading(total)) return <Spinner />;

    const onLoadMore = debounce((size) => {
      if (size >= limit) {
        const max = mobile
          ? DISPLAY_ITEMS.MAX / DISPLAY_ITEMS.MOBILE_DIVIDER
          : DISPLAY_ITEMS.MAX;
        const newLimit = Math.min(
          limit +
            DISPLAY_ITEMS.LOAD_MORE /
              (mobile ? DISPLAY_ITEMS.MOBILE_DIVIDER : 1),
          total,
          max
        );
        if (newLimit > limit) {
          console.debug(`Showing ${newLimit} of ${total} devices`);
          setLimit(newLimit);
        }
      }
    }, 500);

    const onSelectDevice = (deviceId) => {
      setSelectedDevices({
        ...selectedDevices,
        [deviceId]: !selectedDevices?.[deviceId],
      });
    };

    const onSelectAll = () => {
      setSelectedDevices(
        Object.fromEntries(devicesFiltered.map((d) => [d.deviceId, true]))
      );
    };

    const onSelectNone = () => {
      setSelectedDevices({});
    };

    const onSelectCancel = () => {
      setSelectMultiple(false);
      onSelectNone();
    };

    const onMultiOnline = () => {
      const message = DeviceStrings.MULTI_ONLINE_CONFIRM.replace(
        "{x}",
        selectedCount
      );
      confirm({ message })
        .then(() => {
          ccOnline({
            projectId,
            deviceId: selectedDeviceIds,
          }).then((result) => {
            if (result.success) {
              enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn("ccOnline", result.errors[0]);
            }
          });
        })
        .catch(() => {});
    };

    const onMultiCompliance = () => {
      const message = DeviceStrings.MULTI_COMPLIANCE_CONFIRM.replace(
        "{x}",
        selectedCount
      );
      confirm({ message })
        .then(() => {
          ccCompliance({
            projectId,
            deviceId: selectedDeviceIds,
          }).then((result) => {
            if (result.success) {
              enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn("ccCompliance", result.errors[0]);
            }
          });
        })
        .catch(() => {});
    };

    const onMultiDeploy = () => {
      const message = DeviceStrings.MULTI_DEPLOY_CONFIRM.replace(
        "{x}",
        selectedCount
      );
      confirm({ message })
        .then(() => {
          ccDeploy({
            projectId,
            deviceId: selectedDeviceIds,
          }).then((result) => {
            if (result.success) {
              enqueueSnackbar(DeviceStrings.REQUEST_SENT, {
                variant: "success",
              });
            } else {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn("ccDeploy", result.errors[0]);
            }
          });
        })
        .catch(() => {});
    };

    const onChangeCountry = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedCountries([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedCountries(selected.slice(1));
      } else {
        setSelectedCountries(selected);
      }
    };

    const onChangeRetailer = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedRetailers([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedRetailers(selected.slice(1));
      } else {
        setSelectedRetailers(selected);
      }
    };

    const onChangeTag = (e) => {
      const selected = e.target.value;
      if (
        selected[selected.length - 1] === DeviceStrings.FILTER_ALL ||
        selected.length === 0
      ) {
        // select only all or select all when unselected all other items
        setSelectedTags([DeviceStrings.FILTER_ALL]);
      } else if (
        selected[0] === DeviceStrings.FILTER_ALL &&
        selected.length > 1
      ) {
        // clear all
        setSelectedTags(selected.slice(1));
      } else {
        setSelectedTags(selected);
      }
    };

    const onRenderCountries = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} countr${s.length <= 1 ? "y" : "ies"}`;
    };

    const onRenderRetailers = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} retailer${s.length <= 1 ? "" : "s"}`;
    };

    const onRenderTags = (s) => {
      if (s[0] === DeviceStrings.FILTER_ALL) return DeviceStrings.FILTER_ALL;
      return `${s.length} tag${s.length <= 1 ? "" : "s"}`;
    };

    const onClickClear = () => {
      setSelectedCountries([DeviceStrings.FILTER_ALL]);
      setSelectedRetailers([DeviceStrings.FILTER_ALL]);
      setSelectedTags([DeviceStrings.FILTER_ALL]);
    };

    const emptyContent = mobile ? (
      <MobileContent
        title={DeviceStrings.PLACEHOLDER_TITLE}
        desc={DeviceStrings.PLACEHOLDER_DESC_MOBILE}
      />
    ) : (
      <InstructionContent
        title={DeviceStrings.PLACEHOLDER_TITLE}
        desc={DeviceStrings.PLACEHOLDER_DESC}
      />
    );

    const selectContent = selectMultiple && (
      <div className={classes.multiRow}>
        <Button className={classes.multiButton} onClick={onSelectAll}>
          {DeviceStrings.MULTI_SELECT_ALL}
        </Button>
        <Button className={classes.multiButton} onClick={onSelectNone}>
          {DeviceStrings.MULTI_SELECT_NONE}
        </Button>
        <Button className={classes.multiButton} onClick={onSelectCancel}>
          {DeviceStrings.MULTI_SELECT_CANCEL}
        </Button>
      </div>
    );

    const actionContent = selectMultiple && (
      <div className={classes.multiRow}>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled}
          onClick={onMultiOnline}
        >
          {DeviceStrings.ONLINE_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled}
          onClick={onMultiCompliance}
        >
          {DeviceStrings.COMPLIANCE_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled}
          onClick={onMultiDeploy}
        >
          {DeviceStrings.DEPLOY_BUTTON}
        </Button>
        <Button
          className={classes.multiButton}
          disabled={actionsDisabled}
          onClick={() => {
            setShowDialog(true);
          }}
        >
          {DeviceStrings.TRANSFER_BUTTON}
        </Button>
      </div>
    );

    const filterContent = (
      <div className={classes.filter}>
        <Dropdown
          label={DeviceStrings.FILTER_TAG}
          items={tagsItems}
          selected={selectedTags}
          onRenderValue={onRenderTags}
          onChange={onChangeTag}
        />
        <Dropdown
          label={DeviceStrings.FILTER_COUNTRY}
          items={countriesItems}
          selected={selectedCountries}
          onRenderValue={onRenderCountries}
          onChange={onChangeCountry}
        />
        <Dropdown
          label={DeviceStrings.FILTER_RETAILER}
          items={retailersItems}
          selected={selectedRetailers}
          onRenderValue={onRenderRetailers}
          onChange={onChangeRetailer}
        />
        <Button onClick={onClickClear}>Clear</Button>
      </div>
    );

    const configDialog = {
      onClose: () => {
        setShowDialog(false);
      },
      large: true,
    };

    return total > 0 ? (
      <>
        {filterContent}
        {selectContent}
        {actionContent}
        <List
          devices={devicesFiltered}
          max={total}
          onLoadMore={onLoadMore}
          onClickDevice={onClickDevice}
          onSelectDevice={onSelectDevice}
          selectedDevices={selectedDevices}
          multiple={selectMultiple}
          projectId={projectId}
        />
        <MyDialog open={showDialog} config={configDialog}>
          <div
            style={{
              height: 600, // in order to stretch MyDialog
            }}
          >
            <DeviceTransfer
              deviceId={selectedDeviceIds}
              projectId={projectId}
              permissions={permissions}
              active={true}
              dismiss={deviceTransferred}
            />
          </div>
        </MyDialog>
      </>
    ) : live ? (
      emptyContent
    ) : (
      <></>
    );
  },
  isDeviceListEqual
);

export default DeviceList;
