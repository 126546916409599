import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

import {
  useDeviceOnline,
  useDeviceLastOnlineTimestamp,
  requestOnline,
} from "../../services/DeviceService";
import { ccOnline } from "../../services/ApiService";
import { useRelativeTimeString } from "../../utils/localeUtils";

import ButtonCard from "./ButtonCard";

import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";

import { DefaultStrings, DeviceStrings } from "../../strings";

const ONLINE_BUFFER = 5 * 1000; // 5 sec

const DeviceOnlineCard = ({
  projectId,
  deviceId,
  showDesc,
  canRead,
  canEdit,
  useCc = false, // CC = old cloud control app
}) => {
  const [inProgress, setInProgress] = useState([]);
  const [requestTimestamp, setRequestTimestamp] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(true);

  const online = useDeviceOnline(deviceId);
  const icon = online ? <WifiIcon /> : <WifiOffIcon />;
  const title = online
    ? DeviceStrings.ONLINE_TITLE_ON
    : DeviceStrings.ONLINE_TITLE_OFF;
  const lastOnlineTimestamp = useDeviceLastOnlineTimestamp(deviceId);
  const lastOnline =
    useRelativeTimeString(lastOnlineTimestamp) || DeviceStrings.NEVER;

  const deviceLoading = inProgress.includes(deviceId);

  // track mounted state
  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      requestTimestamp &&
      lastOnlineTimestamp &&
      lastOnlineTimestamp - requestTimestamp >= -ONLINE_BUFFER
    ) {
      // stop progress
      if (mounted.current && deviceLoading)
        setInProgress(inProgress.filter((i) => i !== deviceId));
    }
  }, [
    lastOnlineTimestamp,
    requestTimestamp,
    deviceId,
    inProgress,
    deviceLoading,
  ]);

  const request = () => {
    setInProgress([...inProgress, deviceId]);
    setRequestTimestamp(Date.now());

    const call = useCc ? requestOnline : ccOnline;
    call({ projectId, deviceId }).then((result) => {
      if (result.success) {
        enqueueSnackbar(DeviceStrings.REQUEST_SENT, { variant: "success" });
      } else {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(result.errors[0]);
      }
    });
  };

  const configCard = {
    icon,
    title: useCc ? `${title} (CC)` : title,
    buttonLabel: DeviceStrings.ONLINE_BUTTON,
    desc: showDesc ? DeviceStrings.ONLINE_DESC : null,
    fullHeight: true,
    onClick: () => {
      request();
    },
    progress: deviceLoading,
    disableButton: deviceLoading || !canEdit,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      <div>
        <b>{DeviceStrings.LAST_ONLINE}</b>
        {lastOnline && " " + lastOnline}
      </div>
    </ButtonCard>
  );
};

export default DeviceOnlineCard;
