import React from "react";

import { useMobileLayout } from "hooks/uiHooks";
import { useStyles } from "./styles";
import InfiniteScroll from "ui/InfiniteScroll";
import DeviceCard from "ui/cards/DeviceCard";
import {DeviceStrings} from "strings";

const List = ({
    devices,
    max,
    onLoadMore,
    onClickDevice,
    onSelectDevice,
    selectedDevices,
    multiple,
    projectId,
  }) => {
    const mobile = useMobileLayout();
    const classes = useStyles(mobile);
  
    const more = max - devices.length;
  
    const configScroll = {
      onLoadMore,
    };
  
    const configCard = {
      onClick: onClickDevice,
      onSelect: onSelectDevice,
      showSelect: multiple,
    };
  
    return (
      <div className={classes.devicesRoot}>
        <InfiniteScroll config={configScroll} size={devices.length} max={max}>
          <div className={classes.devicesMain}>
            {devices.map((device) => (
              <div
                key={`DeviceCard-${device?.deviceId}`}
                className={classes.deviceCardContainer}
              >
                {device && (
                  <DeviceCard
                    info={device}
                    projectId={projectId}
                    config={{
                      ...configCard,
                      selected: !!selectedDevices?.[device?.deviceId],
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </InfiniteScroll>
        {more > 0 && (
          <div className={classes.devicesMore}>
            {DeviceStrings.LIST_MORE_HIDDEN_DEVICES.replace("{}", more)}
          </div>
        )}
      </div>
    );
};
export default List;