import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  multiRow: {
    marginTop: 16,
    display: "flex",
    justifyContent: "center",
  },
  multiButton: {
    margin: "4px 8px",
    padding: "4px 8px",
  },
  filter: (mobile) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 16,
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  filterButton: {
    marginLeft: 16,
  },
}));