import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "ui/Spinner";

import MyCard from "./MyCard";
import MyDialog from "../MyDialog";

import DevicesOutlinedIcon from "@material-ui/icons/DevicesOutlined";
import {
  useLiveDevicesCount,
  useCompliantDevicesCount,
  useOnlineDevicesCount,
  useDecommissionedDevicesCount,
} from "../../services/DeviceService";
import { DeviceStrings, ProjectStrings } from "../../strings";
import { useProjectDevicesOnlineState } from "services/ProjectService";
import { MemoizedChart } from "ui/charts/HeartBeatLineChart";
import Divider from "@material-ui/core/Divider";
import { useMobileLayout } from "../../hooks/uiHooks";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(1),
    color: "black",
    fontSize: 35,
    fontWeight: 500,
  },
  chartContainer: {
    display: "flex",
    justifyContent: "start",
    marginTop: 25,
    marginBottom: 25,
    alignItems: "center",
    fontWeight: "bold",
    lineHeight: 1.66,
  },
}));

const ContentChild = ({ header, content }) => {
  const classes = useStyles();
  return (
    <Box flexGrow="1">
      <div>{header}</div>
      <div className={classes.content}>{content}</div>
    </Box>
  );
};

const DeviceStatCard = ({ projectId, canRead }) => {
  const [showDialog, setShowDialog] = useState(false);
  const live = useLiveDevicesCount(canRead && projectId);
  const online = useOnlineDevicesCount(canRead && projectId);
  const mobile = useMobileLayout();
  const charts = useProjectDevicesOnlineState(projectId, 90);

  const compliant = useCompliantDevicesCount(canRead && projectId);
  const decommissioned = useDecommissionedDevicesCount(canRead && projectId);

  const classes = useStyles();

  const configCard = {
    icon: <DevicesOutlinedIcon />,
    title: ProjectStrings.OVERVIEW_DEVICES_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
  };

  const configDialog = {
    icon: <DevicesOutlinedIcon />,
    title: ProjectStrings.OVERVIEW_DEVICES_TITLE,
    onClose: () => {
      setShowDialog(false);
    },
    large: true,
  };

  const chartsConverted =
    charts &&
    Object.entries(charts)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([k, v]) => ({ date: k, value: v }));

  const formatLabel = (value, date) => {
    return [
      value > 1
        ? `${value}${ProjectStrings.CHART_DEVICES_ONLINE}`
        : `${value}${ProjectStrings.CHART_DEVICE_ONLINE}`,
      date.split("-").reverse().join("-"),
    ];
  };

  const Content = ({ showDecommissioned = false, showChart }) => (
    <>
      <div
        style={{
          width: showDialog && !mobile && showChart ? "50%" : "100%",
        }}
      >
        <Box display="flex">
          <ContentChild header={DeviceStrings.STAT_TOTAL} content={live} />
          <ContentChild header={DeviceStrings.STAT_ONLINE} content={online} />
          <ContentChild
            header={DeviceStrings.STAT_COMPLIANT}
            content={compliant}
          />
          {showDecommissioned && (
            <ContentChild
              header={DeviceStrings.STAT_DECOMMISSIONED}
              content={decommissioned}
            />
          )}
        </Box>
      </div>

      {showChart && !mobile && (
        <>
          <Divider />
          <div className={classes.chartContainer}>
            <span
              className="material-symbols-outlined"
              style={{ paddingRight: 16 }}
            >
              monitor_heart
            </span>
            <span>{ProjectStrings.CHART_HEARTBEATS_TITLE}</span>
          </div>
          <div
            style={{
              minHeight: "50vh",
            }}
          >
            <>
              {chartsConverted ? (
                <MemoizedChart
                  yAxisMaxLength={live}
                  chartHeatsBeatsData={chartsConverted}
                  xAxisLabel={ProjectStrings.CHART_HEARTBEATS_X_LABEL}
                  yAxisLabel={ProjectStrings.CHART_HEARTBEATS_Y_LABEL}
                  formatLabel={formatLabel}
                />
              ) : (
                <Spinner />
              )}
            </>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      <MyCard config={configCard} canRead={canRead} canEdit={canRead}>
        <Content />
      </MyCard>
      <MyDialog open={showDialog} config={configDialog}>
        <Content showDecommissioned showChart />
      </MyDialog>
    </>
  );
};

export default DeviceStatCard;
