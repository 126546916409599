import React, { useState } from "react";
import { TextField } from "@material-ui/core";

import { useDefaultCommands, useDefaultApps } from "../../services/TaskService";

import ButtonCard from "./ButtonCard";
import MySelect from "../MySelect";

import { ProjectStrings } from "../../strings";

const ApplicationSelect = ({ value, onChange }) => {
  //   const projectApps = useProjectAppsAll(canRead && projectId);
  const apps = useDefaultApps();

  return (
    <MySelect
      title="Application"
      value={value}
      onChange={onChange}
      options={apps}
    />
  );
};

const CustomInput = ({ input, values, onChange }) => {
  if (!input) return <></>;

  const onChangeInternal = (event, key) => {
    if (onChange) onChange(key, event.target.value);
  };

  return input.map(({ key, target, title }) => {
    switch (target) {
      case "APPLICATION":
        return (
          <ApplicationSelect
            key={key}
            value={values?.[key] || ""}
            onChange={(e) => onChangeInternal(e, key)}
          />
        );
      default:
        return (
          <TextField
            key={key}
            style={{ margin: 5 }}
            label={title}
            value={values?.[key] || ""}
            fullWidth
            onChange={(e) => onChangeInternal(e, key)}
          />
        );
    }
  });
};

const AddTasksCard = ({
  title,
  projectId,
  description,
  canRead,
  onAddTask,
}) => {
  //   const defaultTasks = useDefaultTasks(stage);
  const commands = useDefaultCommands();

  const [category, setCategory] = useState("");
  const [commandType, setCommandId] = useState("");
  const [input, setInput] = useState({});

  const commandTypes =
    commands &&
    Object.fromEntries(
      Object.values(commands).map((c) => [
        c.category,
        c.category.charAt(0) + c.category.slice(1).toLowerCase(),
      ])
    );
  const selectedCommands =
    commandTypes &&
    category &&
    Object.fromEntries(
      Object.entries(commands).filter(
        ([k, v]) => v.category.toLowerCase() === category.toLowerCase()
      )
    );

  const onAddTaskInternal = () => {
    onAddTask({ projectId, commandType, input }).then((res) => {
      console.debug("onAddTask", res);
    });
  };

  const onChangeType = (event) => {
    setCategory(event.target.value);

    setCommandId("");
    setInput({});
  };

  const onChangeCommandId = (event) => {
    const id = event.target.value;
    setCommandId(id);

    setInput({});
  };
  const onChangeCustomInput = (key, value) => {
    setInput((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const configCard = {
    icon: <span className="material-symbols-outlined">assignment_add</span>,
    title,
    desc: description,
    buttonLabel: ProjectStrings.TASKS_ADD_TASK_BUTTON,
    onClick: onAddTaskInternal,
    fullHeight: true,
    // disableButton: !commandInput,
    progress: false,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {commandTypes && (
        <MySelect
          title="Category"
          value={category}
          onChange={onChangeType}
          options={commandTypes}
        />
      )}
      {selectedCommands && (
        <MySelect
          title="Task Type"
          value={commandType}
          onChange={onChangeCommandId}
          options={selectedCommands}
        />
      )}

      {commands?.[commandType]?.input && (
        <CustomInput
          input={commands[commandType].input}
          values={input}
          onChange={onChangeCustomInput}
        />
      )}
    </ButtonCard>
  );
};

export default AddTasksCard;
