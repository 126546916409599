import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { useDeviceRecoveries } from "../services/DeviceService";

import OverflowTypography from "../ui/OverflowTypography";
import MyAppBar from "../ui/MyAppBar";

import { DeviceStrings } from "../strings";

const useStyles = makeStyles((theme) => ({
  page: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    margin: theme.spacing(2),
    marginTop: 0,
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginRight: theme.spacing(1),
  },
}));

const RecoveryRow = ({ title, label }) => {
  const classes = useStyles();
  if (!title || !label) return <></>;
  return (
    <div className={classes.row}>
      <OverflowTypography variant="caption" noWrap className={classes.title}>
        {title}
      </OverflowTypography>
      <span>{label}</span>
    </div>
  );
};

const RecoveryCard = ({ recovery }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  if (!recovery) return <></>;
  const { deviceId, projectId, recoveryCode, expiryMoment } = recovery;
  const onClick = () => {
    navigator.clipboard.writeText(recoveryCode);
    enqueueSnackbar(DeviceStrings.RECOVERY_CODE_COPIED, { variant: "info" });
  };
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent className={classes.content}>
          <RecoveryRow
            title={DeviceStrings.RECOVERY_DEVICE_ID}
            label={deviceId}
          />
          <RecoveryRow
            title={DeviceStrings.RECOVERY_PROJECT_ID}
            label={projectId}
          />
          <RecoveryRow
            title={DeviceStrings.RECOVERY_EXPIRY}
            label={expiryMoment}
          />
          <RecoveryRow
            title={DeviceStrings.RECOVERY_CODE}
            label={recoveryCode}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const DeviceRecoveryPage = () => {
  const recoveries = useDeviceRecoveries();
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <MyAppBar
        config={{
          title: DeviceStrings.RECOVERY_TITLE,
        }}
      />
      <div className={classes.container}>
        {recoveries?.map((r, i) => (
          <RecoveryCard key={`recovery-${i}`} recovery={r} />
        ))}
      </div>
    </div>
  );
};

export default DeviceRecoveryPage;
