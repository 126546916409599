import { useRef, useEffect, useCallback, useState } from "react";

import IFrameService from "./IFrameService";
import { updateShowroomLink } from "./ApiService";
import { uploadFile, deleteFile } from "./StorageService";
import { getMediaLink } from "./MediaService";
import { CUSTOMISE_QR_URL } from "configs/urlConfig";

const CONFIG = { siteOrigin: CUSTOMISE_QR_URL };

const ACTIONS = {
  READY: "READY",
  QR_SETUP: "QR_SETUP",
  QR_UPDATE_CONFIRM: "QR_UPDATE_CONFIRM",
  UPDATE: "UPDATE",
};

const TAG = "[useCustomQRMessageService]:";

function useCustomQRMessageService(ref, mediaLink, projectId, isShowingDialog) {
  const [updateReceived, setUpdateReceived] = useState(false);
  const iframeRef = useRef(null);

  const onReady = useCallback(async () => {
    if (!mediaLink || !iframeRef.current?.current) return;
    console.debug(`${TAG} onReady`);
    setUpdateReceived(false);
    const mediaLinkInit = await getMediaLink(mediaLink.linkId);
    const { linkId, name, url, qrSetup } = mediaLinkInit;

    const payload = {
      projectId,
      linkId,
      name,
      ...(qrSetup ?? {}),
      url,
    };

    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.QR_SETUP,
      payload,
    });
  }, [mediaLink, projectId]);

  const onUpdate = useCallback(
    async (args) => {
      console.debug(`${TAG} onUpdate`);
      const { linkId, qrSetup, image } = args;
      if (mediaLink.linkId !== linkId) return;

      let data = {
        ...mediaLink,
        expiry: mediaLink.expiry && mediaLink.expiry.toDate(),
        projectId,
        qrSetup,
      };

      if (image?.action === "upload") {
        const { file, path, ...restImage } = image;
        const url = await uploadFile({ file, path });

        delete restImage.action;
        data = {
          ...data,
          qrSetup: { ...qrSetup, image: { ...restImage, url } },
        };
      } else if (image?.action === "remove") {
        const { path, action, url, fileType, ...restImage } = image;
        await deleteFile({ path });
        data = { ...data, qrSetup: { ...qrSetup, image: { ...restImage } } };
      }

      await updateShowroomLink(data);
      setUpdateReceived(true);
    },
    [mediaLink, projectId]
  );

  const onQRConfirm = () => {
    console.debug(`${TAG} onConfirm: close dialog`);
    IFrameService.postMessage(iframeRef.current, CONFIG.siteOrigin, {
      action: ACTIONS.QR_UPDATE_CONFIRM,
    });
  };

  const onMessage = useCallback(
    (data) => {
      const { action, payload } = data.detail;
      switch (action) {
        case ACTIONS.READY:
          onReady(payload);
          break;
        case ACTIONS.UPDATE:
          onUpdate(payload);
          break;

        default:
          console.warn(
            `${TAG} unknown message: ${JSON.stringify(data.detail)}${action}`
          );
          break;
      }
    },
    [onReady, onUpdate]
  );

  useEffect(() => {
    if (!mediaLink) return;

    // unsubscribe existing
    if (iframeRef.current && !isShowingDialog && updateReceived) {
      console.log(`${TAG} unsubscribeMessage: dialog closed`, mediaLink.linkId);

      iframeRef.current = null;
      IFrameService.unsubscribeMessage(CONFIG.siteOrigin, onMessage);
    }

    // subscribe existing
    if (ref && !iframeRef.current && isShowingDialog) {
      console.log(`${TAG} subscribeMessage`, mediaLink.linkId);
      iframeRef.current = ref;
      IFrameService.subscribeMessage(CONFIG.siteOrigin, onMessage, ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, isShowingDialog, updateReceived]);

  return { onQRConfirm };
}

export default useCustomQRMessageService;
