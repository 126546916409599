import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { useProjectInstallApps } from "../../services/ProjectService";
import {
  useDefaultApps,
  useDefaultApp,
  useDefaultAppVariant,
  useDefaultAppVariantVersion,
  useDefaultAppVariantAllVersions,
} from "../../services/TaskService";
import {
  projectInstallParamsInstallAppsSet,
  projectInstallParamsInstallAppsSetVersions,
} from "../../services/ApiService";
import { useMobileLayout } from "../../hooks/uiHooks";

import RestrictedContent from "../RestrictedContent";
import ButtonCard from "../cards/ButtonCard";
import MyDialog from "../MyDialog";
import MySelect from "../MySelect";

import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import { DefaultStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  main: (mobile) => ({
    padding: theme.spacing(mobile ? 1 : 2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),
  accordionMain: {
    backgroundColor: theme.palette.background.default,
  },
}));

const AppVariantVersion = ({ appId, variantId, versionId }) => {
  const variant = useDefaultAppVariant(appId, variantId);
  const version = useDefaultAppVariantVersion(appId, variantId, versionId);
  return <Chip label={`${variant?.alias}: ${version?.ver}`} />;
};

const AppRow = ({ appId, variants, onClick }) => {
  const appInfo = useDefaultApp(appId);
  const classes = useStyles();
  const onClickInternal = () => {
    if (onClick) onClick(appId);
  };
  return (
    <Accordion className={classes.accordionMain}>
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
        <span>{appInfo?.title}</span>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ display: "flex", gap: 8, flexGrow: 1 }}>
            {variants?.map(([k, v]) => (
              <AppVariantVersion
                key={`variant-${k}`}
                appId={appId}
                variantId={k}
                versionId={v}
              />
            ))}
          </div>
          <Button
            variant="contained"
            onClick={onClickInternal}
            // disabled={disabled}
          >
            Edit Versions
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const EditVersionRow = ({ appId, variantId, versionId, onChange }) => {
  const variant = useDefaultAppVariant(appId, variantId);
  const versions = useDefaultAppVariantAllVersions(appId, variantId);
  const onChangeInternal = (event) => {
    if (onChange) onChange(appId, variantId, event.target.value);
  };
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8, padding: 8 }}>
      <div style={{ flexGrow: 1 }}>
        <MySelect
          title={variant?.alias}
          value={versionId}
          onChange={onChangeInternal}
          options={versions}
          titleKey="ver"
        />
      </div>
    </div>
  );
};

const versionsHash = (vers) =>
  vers &&
  Object.entries(vers)
    .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
    .map(([k, v]) => `${k}=${v}`)
    .join(";");

const EditVersionsDialog = ({
  projectId,
  appId,
  appVariants,
  open,
  setOpen,
}) => {
  const app = useDefaultApp(appId);
  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (!appVariants) return;
    setSelected(appVariants);
  }, [appVariants]);

  useEffect(() => {
    if (!open) setSelected({});
  }, [open]);

  const onChange = (id1, id2, id3) => {
    setSelected((s) => ({ ...s, [id2]: id3 }));
  };

  const onClose = () => {
    setOpen(false);
    if (appVariants) setSelected(appVariants);
  };

  const onOk = () => {
    console.debug("Update Versions:", appId, selected);

    projectInstallParamsInstallAppsSetVersions({
      projectId,
      appsVersions: { [appId]: selected },
    })
      .then(() => {
        enqueueSnackbar("Applications configuration updated", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const configDialog = {
    icon: <AppsOutlinedIcon />,
    title: app?.title,
    onClose: onClose,
    onOk: onOk,
    disableOk:
      appVariants && versionsHash(appVariants) === versionsHash(selected),
  };

  return (
    <MyDialog open={open} config={configDialog}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {appVariants &&
          Object.entries(appVariants)
            .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
            .map(([k, v]) => (
              <EditVersionRow
                key={`variant-${k}`}
                appId={appId}
                variantId={k}
                versionId={k in selected ? selected[k] : v}
                onChange={onChange}
              />
            ))}
      </div>
    </MyDialog>
  );
};

const ManageAppsRow = ({ appId, selected, onClick }) => {
  const appInfo = useDefaultApp(appId);

  const onClickInternal = () => {
    if (onClick) onClick(appId);
  };
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <span style={{ flexGrow: 1 }}>{appInfo?.title}</span>
      <Checkbox
        color="primary"
        disabled={appInfo?.required}
        checked={selected}
        onClick={onClickInternal}
      />
    </div>
  );
};

const appsHash = (ids) => ids?.sort().join(";");

const ManageAppsDialog = ({ projectId, open, setOpen }) => {
  const projectApps = useProjectInstallApps(projectId);
  const apps = useDefaultApps();
  const { enqueueSnackbar } = useSnackbar();

  // sort apps by required first then name
  const appsSorted =
    apps &&
    Object.entries(apps)
      .sort(([k1, v1], [k2, v2]) => v1.title.localeCompare(v2.title))
      .sort(([k1, v1], [k2, v2]) => Number(v2.required) - Number(v1.required));

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!projectApps) return;
    setSelected(Object.keys(projectApps.items));
  }, [projectApps]);

  const onClickApp = (id) => {
    if (selected.includes(id)) setSelected((s) => s.filter((i) => i !== id));
    else setSelected((s) => s.concat(id));
  };

  const onClose = () => {
    setOpen(false);
    if (projectApps?.items) setSelected(Object.keys(projectApps?.items));
  };

  const onOk = () => {
    console.debug("Update Apps:", selected);

    projectInstallParamsInstallAppsSet({ projectId, apps: selected })
      .then(() => {
        enqueueSnackbar("Applications configuration updated", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(DefaultStrings.ERROR_MSG, {
          variant: "error",
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const configDialog = {
    icon: <AppsOutlinedIcon />,
    title: "Manage Applications",
    onClose: onClose,
    onOk: onOk,
    disableOk:
      projectApps?.items &&
      appsHash(Object.keys(projectApps.items)) === appsHash(selected),
  };

  return (
    <MyDialog open={open} config={configDialog}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {appsSorted?.map(([k, v]) => (
          <div key={`manage-${k}`}>
            <ManageAppsRow
              key={`variant-${k}`}
              appId={k}
              selected={selected?.includes(k)}
              onClick={onClickApp}
            />
          </div>
        ))}
      </div>
    </MyDialog>
  );
};

const EditAppsTab = ({ projectId, canRead }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const projectApps = useProjectInstallApps(projectId);

  const [appId, setAppId] = useState("");
  const [appVariants, setAppVariants] = useState({});
  const [showEditVersionsDialog, setShowEditVersionsDialog] = useState(false);
  const [showManageAppsDialog, setShowManageAppsDialog] = useState(false);

  const onClickEditVersions = (appId) => {
    setAppId(appId);
    setAppVariants(projectApps.items[appId]);
    setShowEditVersionsDialog(true);
  };

  const configCard = {
    icon: <AppsOutlinedIcon />,
    title: "Applications",
    desc: "Configure applications to be installed on device.",
    buttonLabel: "Manage Apps",
    onClick: () => {
      setShowManageAppsDialog(true);
    },
    fullHeight: true,
    // disableButton: !canWrite,
    // progress,
  };

  return (
    <RestrictedContent permitted={canRead}>
      <div className={classes.main}>
        <ButtonCard config={configCard} canRead={canRead}>
          {projectApps?.items &&
            Object.entries(projectApps.items)
              .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
              .map(([id, app]) => (
                <AppRow
                  key={`app-${id}`}
                  appId={id}
                  variants={
                    app &&
                    Object.entries(app).sort(([k1, v1], [k2, v2]) =>
                      k1.localeCompare(k2)
                    )
                  }
                  onClick={onClickEditVersions}
                />
              ))}
        </ButtonCard>
      </div>
      <EditVersionsDialog
        projectId={projectId}
        open={showEditVersionsDialog}
        setOpen={setShowEditVersionsDialog}
        appId={appId}
        appVariants={appVariants}
      />
      <ManageAppsDialog
        projectId={projectId}
        open={showManageAppsDialog}
        setOpen={setShowManageAppsDialog}
      />
    </RestrictedContent>
  );
};

export default EditAppsTab;
