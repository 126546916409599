import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DeviceOnlineCard from "./cards/DeviceOnlineCard";
import DeviceComplianceCard from "./cards/DeviceComplianceCard";
import DeviceDeploymentCard from "./cards/DeviceDeploymentCard";
import DeviceRemoteCard from "./cards/DeviceRemoteCard";
import DeviceConnectedCard from "./cards/DeviceConnectedCard";
import RestrictedContent from "./RestrictedContent";
import Spinner from "./Spinner";
import DeviceTransfer from "./DeviceTransfer";

import { useMobileLayout } from "../hooks/uiHooks";
import { areDictsEqual } from "../utils/generalUtils";
import { isLoading } from "../utils/uiUtils";

const useStyles = makeStyles((theme) => ({
  root: (mobile) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: mobile ? "column" : "flex",
    overflowY: mobile ? "auto" : "hidden",
    padding: theme.spacing(mobile ? 1 : 2),
  }),
  group: (mobile) => ({
    flex: 1,
    display: "flex",
    flexDirection: mobile ? "column" : "flex",
    "&:nth-child(n+2)": {
      ...(mobile && { marginTop: theme.spacing(2) }),
      ...(!mobile && { marginLeft: theme.spacing(2) }),
    },
  }),
  item: (mobile) => ({
    flex: 1,
    display: "flex",
    flexDirection: mobile ? "column" : "flex",
    "&:nth-child(n+2)": {
      ...(mobile && { marginTop: theme.spacing(2) }),
      ...(!mobile && { marginLeft: theme.spacing(2) }),
    },
  }),
  itemVertical: (mobile) => ({
    "&:nth-child(n+2)": {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  }),
  ccCardContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    "&:first-child": {
      marginTop: 0,
    },
  },
}));

const DeviceCC = ({
  deviceId,
  projectId,
  halfOpened,
  permissions,
  active,
  dismiss,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  console.debug("DeviceCC", projectId, deviceId, halfOpened);

  if (isLoading(deviceId)) return <Spinner />;

  const fullSize = mobile || !halfOpened;

  return (
    <RestrictedContent permitted={permissions?.canReadDevices}>
      <div className={classes.root}>
        <div
          className={classes.group}
          style={{
            flexDirection: "column",
          }}
        >
          <div className={`${classes.item} ${classes.itemVertical}`}>
            <DeviceOnlineCard
              projectId={projectId}
              deviceId={deviceId}
              showDesc={fullSize}
              canRead={permissions?.canReadDevices}
              canEdit={permissions?.canWriteDevices}
              useCc={true}
            />
          </div>
          <div className={`${classes.item} ${classes.itemVertical}`}>
            <DeviceComplianceCard
              deviceId={deviceId}
              projectId={projectId}
              showDesc={fullSize}
              canRead={permissions?.canReadDevices}
              canEdit={permissions?.canWriteDevices}
              useCc={true}
            />
          </div>
          <div className={`${classes.item} ${classes.itemVertical}`}>
            <DeviceDeploymentCard
              deviceId={deviceId}
              projectId={projectId}
              showDesc={fullSize}
              canRead={permissions?.canReadDevices}
              canEdit={permissions?.canWriteDevices}
              useCc={true}
            />
          </div>
        </div>
        <div
          className={classes.group}
          style={{
            flexGrow: 2,
            flexDirection: "column",
          }}
        >
          <div className={`${classes.item} ${classes.itemVertical}`}>
            <div className={classes.group}>
              <div className={classes.item}>
                <DeviceRemoteCard
                  deviceId={deviceId}
                  showDesc={fullSize}
                  canRead={permissions?.canReadDevices}
                  canEdit={permissions?.canWriteDevices}
                />
              </div>
              <div
                className={classes.item}
                style={{
                  minHeight: 180,
                }}
              >
                <DeviceConnectedCard
                  deviceId={deviceId}
                  canRead={permissions?.canReadDevices}
                  canEdit={permissions?.canWriteDevices}
                />
              </div>
            </div>
          </div>
          <div
            className={`${classes.item} ${classes.itemVertical}`}
            style={{
              flexGrow: 4,
              minHeight: mobile ? "75vh" : "auto",
            }}
          >
            <DeviceTransfer
              deviceId={deviceId}
              projectId={projectId}
              permissions={permissions}
              active={active}
              dismiss={dismiss}
              useCc={true}
            />
          </div>
        </div>
      </div>
    </RestrictedContent>
  );
};

const isEqual = (props1, props2) =>
  props1?.projectId === props2?.projectId &&
  props1?.deviceId === props2?.deviceId &&
  props1?.halfOpened === props2?.halfOpened &&
  props1?.live === props2?.live &&
  props1?.active === props2?.active &&
  props1?.dismiss === props2?.dismiss &&
  areDictsEqual(props1.permissions, props2.permissions);

export default memo(DeviceCC, isEqual);
