import React, { memo, useState } from "react";

import { updateUrl } from "services/UiService";

import DevicePanel from "../DevicePanel";
import RestrictedContent from "../RestrictedContent";

import { getProjectDevicesRoute } from "../../route";
import { DeviceList } from "./components";
import { isEqual } from "./utils";

const DeviceListWithPanel = ({
  projectId,
  filter,
  live,
  sortOption,
  sortDesc,
  permissions,
  default: { deviceId, tab },
  multiple,
}) => {
  // panel control
  const [panelOpts, setPanelOpts] = useState({
    deviceId,
    tab,
  });
  const [activeDeviceId, setActiveDeviceId] = useState(panelOpts?.deviceId);

  const onClickDevice = (deviceId) => {
    setActiveDeviceId(deviceId);
  };

  const configPanel = {
    onClose: () => {
      setActiveDeviceId(null);
      setPanelOpts(null); // override defaults
      updateUrl(getProjectDevicesRoute(projectId));
    },
    expand: !!panelOpts?.deviceId,
    tab: panelOpts?.tab,
  };

  console.debug(
    "DeviceListWithPanel",
    activeDeviceId,
    `expand=${configPanel.expand}`
  );

  return (
    <RestrictedContent permitted={permissions?.canReadDevices}>
      <DeviceList
        filter={filter}
        sortOption={sortOption}
        sortDesc={sortDesc}
        projectId={projectId}
        live={live}
        permissions={permissions}
        onClickDevice={onClickDevice}
        multiple={multiple}
      />
      <DevicePanel
        config={configPanel}
        deviceId={activeDeviceId}
        projectId={projectId}
        permissions={permissions}
      />
    </RestrictedContent>
  );
};

export default memo(DeviceListWithPanel, isEqual);
