import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { installerUpdateDevice } from "../../services/ApiService";
import { useParams } from "react-router-dom";
import Spinner from "../../ui/Spinner";

import MyDialog from "../../ui/MyDialog";
import { DefaultStrings, DeviceStrings } from "../../strings";
import { useAnonLogin } from "../../services/AuthService";
import SuccessScreen from "./success";
import RegisterDeviceForm from "./form";

const RegisterDevicePage = () => {
  const params = useParams();
  const { deviceId, pinCode = "", country = "GB" } = params;
  const authenticated = useAnonLogin();
  const [inProgress, setInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editStoreInfo, setEditStoreInfo] = useState({ deviceId, country });
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = new URLSearchParams(window.location.search);
  const cmd = urlParams.get("c");

  const update = () => {
    setInProgress(true);
    installerUpdateDevice({ ...editStoreInfo, cmd })
      .then((result) => {
        enqueueSnackbar(DeviceStrings.STORE_UPDATED, { variant: "success" });
        setSuccess(true);
      })
      .catch((err) => {
        let message = DefaultStrings.ERROR_MSG;
        if (JSON.stringify(err).includes("500")) {
          message = DefaultStrings.ERROR_MSG_WRONG_PIN;
        }
        enqueueSnackbar(message, { variant: "error" });
        console.warn(err);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  if (authenticated !== true) {
    return <Spinner />;
  }

  return (
    <>
      {success ? (
        <SuccessScreen />
      ) : (
        <MyDialog open={true} config={{ maximise: true }}>
          <RegisterDeviceForm
            storeInfo={{ deviceId, pinCode, country }}
            editStoreInfo={editStoreInfo}
            setEditStoreInfo={setEditStoreInfo}
            showPin={true}
            onConfirm={update}
            inProgress={inProgress}
          />
          {inProgress && <Spinner />}
        </MyDialog>
      )}
    </>
  );
};

export default RegisterDevicePage;
